import { FILTER_COMPARISON } from "../AnalyzePage.types";
import {
  extractAllSurveyQuestions,
  mapMarkersToGeoJson,
} from "../AnalyzePage.utils";
import { MapRef } from "react-map-gl";
import { MapVisualizerProps, MapVisualizerRef } from "./MapVisualizer.types";
import { QUESTION_TYPE } from "enums";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapVisualizerView from "./MapVisualizer.view";
import { useImmer } from "use-immer";

const MapVisualizer = forwardRef<MapVisualizerRef, MapVisualizerProps>(
  (props, ref) => {
    const {
      onClickDelete,
      onClickResize,
      onClickFilter,
      graphQuestionId,
      filters,
      globallyFilteredRespondents,
      survey,
      id,
    } = props;

    const [data, setData] = useState<{
      type: string;
      features: any;
    }>();
    const [dataSettings, setDataSettings] = useImmer({});
    const [heatmapSettings, setHeatmapSettings] = useImmer({});
    const [graphTitle, setGraphTitle] = useState("");
    const [filteredRespondents, setFilteredRespondents] = useState<
      {
        answers: any;
        meta: any;
        mapMarkers: any;
      }[]
    >([]);
    const [showHeatmap, setShowHeatmap] = useState(false);
    const [showData, setShowData] = useState(true);

    const mapRef = useRef<MapRef>(null);
    const hasMapFitted = useRef(false);

    const fitToScreen = useCallback(
      (hasFitted?: () => void) => {
        if (mapRef.current && data && data.features.length >= 2) {
          const mapCoordinates = data.features.map(
            (feature: any) => feature.geometry.coordinates
          );

          const bounds = new mapboxgl.LngLatBounds();
          mapCoordinates.forEach((coordinates: any) => {
            bounds.extend(coordinates);
          });

          mapRef.current.fitBounds(bounds, {
            padding: 20,
          });

          if (hasFitted) hasFitted();
        }
      },
      [data]
    );

    const handleOnClickDelete = useCallback(() => {
      if (onClickDelete) {
        onClickDelete(id);
      }
    }, [id, onClickDelete]);

    const handleOnClickFilter = useCallback(() => {
      if (onClickFilter) {
        onClickFilter(id);
      }
    }, [id, onClickFilter]);

    const handleOnClickResize = useCallback(() => {
      if (onClickResize) {
        onClickResize(id);
      }
    }, [id, onClickResize]);

    const handleOnClickFitToScreenIcon = useCallback(() => {
      fitToScreen();
    }, [fitToScreen]);

    const handleOnClickHeatmapIcn = useCallback(() => {
      setShowHeatmap((prev) => !prev);
    }, []);

    const handleOnClickDataIcon = useCallback(() => {
      setShowData((prev) => !prev);
    }, []);

    useEffect(() => {
      if (filters.length === 0) {
        setFilteredRespondents(globallyFilteredRespondents);
        return;
      }

      const filteredRespondents = globallyFilteredRespondents.filter((r) => {
        return filters.every((f) => {
          const questionResponse = r.answers.find(
            (a: any) => a.question.id === f.questionId
          );
          /** If the question is not answered, return false */
          if (questionResponse === undefined) return false;
          const { answer } = questionResponse;
          if (f.filterComparison === FILTER_COMPARISON.IS) {
            return answer === f.filterValue;
          }
          if (f.filterComparison === FILTER_COMPARISON.IS_NOT) {
            return answer !== f.filterValue;
          }
          if (f.filterComparison === FILTER_COMPARISON.CONTAINS) {
            return answer.includes(f.filterValue);
          }
          return false;
        });
      });
      setFilteredRespondents(filteredRespondents);
    }, [filters, globallyFilteredRespondents]);

    useEffect(() => {
      if (!survey) return;

      /** Extract all page questions in single array */
      const questions = extractAllSurveyQuestions(survey);

      const graphQuestion = questions.find((q) => q.id === graphQuestionId);
      if (!graphQuestion) return;

      const { type, name } = graphQuestion;
      setGraphTitle(name);

      /** Conditionally map the question types */
      if (type === QUESTION_TYPE.MAP_MARKER) {
        const mapData = mapMarkersToGeoJson(
          filteredRespondents.map((respondent) => respondent.mapMarkers).flat()
        );

        setData(mapData);
      }
    }, [filteredRespondents, graphQuestionId, survey]);

    useEffect(() => {
      if (!hasMapFitted.current)
        fitToScreen(() => {
          hasMapFitted.current = true;
        });
    }, [fitToScreen]);

    return (
      <MapVisualizerView
        data={data}
        dataSettings={dataSettings}
        graphId={graphQuestionId}
        graphTitle={graphTitle}
        filterCount={filters.length}
        showHeatmap={showHeatmap}
        showData={showData}
        heatmapSettings={heatmapSettings}
        onClickFilter={handleOnClickFilter}
        onClickDelete={handleOnClickDelete}
        onClickResize={handleOnClickResize}
        onClickFitToScreenIcon={handleOnClickFitToScreenIcon}
        onClickHeatmapIcon={handleOnClickHeatmapIcn}
        onClickDataIcon={handleOnClickDataIcon}
        mapRef={mapRef}
      />
    );
  }
);

export default MapVisualizer;
