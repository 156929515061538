import Stack from "@mui/material/Stack";
import { AnalyzeViewPageProps } from "./AnalyzePage.types";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { Badge, Box, IconButton, Paper } from "@mui/material";
import { format } from "date-fns";
import { Button } from "components";
import DataVisualizer from "./DataVisualizer";
import FullScreenRow from "layouts/FullScreen";
import MapVisualizer from "./MapVisualizer";
import { ArrowBack, Save as SaveIcon } from "@mui/icons-material";
import { VISUALIZATION_TYPE } from "enums";

const dateFormatter = (date: Date) => {
  return format(new Date(date), "dd-MM-yy");
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          padding: "1px 10px 1px 10px",
          backgroundColor: "rgba(0,0,0,0.5)",
          borderRadius: "4px",
        }}
      >
        <p>{`${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const AnalyzeViewPage = (props: AnalyzeViewPageProps) => {
  const {
    answerDateData,
    globallyFilteredRespondents,
    filterCount,
    visualizers,
    nAnswerPoints,
    nMapMarkers,
    nRespondents,
    onClickAddVisualizer,
    onClickDeleteVisualizer,
    onClickEditVizualiserFilter,
    onClickExportCsv,
    onClickExportGeoJson,
    onClickExportXlsx,
    onClickFilter,
    onClickResizeVisualizer,
    onClickSaveDashboard,
    survey,
    onClickReturnPage,
    surveyId,
    surveyTitle,
  } = props;

  return (
    <FullScreenRow>
      <Stack flex={1} width="100%" height="100%">
        <Stack direction="row" alignItems="center" margin={"20px"}>
          <Stack mr="10px">
            <IconButton sx={{ color: "white" }} onClick={onClickReturnPage}>
              <ArrowBack />
            </IconButton>
          </Stack>
          <Stack alignItems="left" height="40px" flex={1}>
            <Typography color={"white"}>{surveyTitle}</Typography>
            <Typography color="lightgray" fontSize="12px">
              {surveyId}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={4}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              padding={2}
              bgcolor="rgba(255,255,255,0.1)"
              borderRadius="12px"
            >
              <Button
                value="+ Graf"
                onClick={() =>
                  onClickAddVisualizer &&
                  onClickAddVisualizer(VISUALIZATION_TYPE.GRAPH)
                }
              />
              <Button
                value="+ Karta"
                onClick={() =>
                  onClickAddVisualizer &&
                  onClickAddVisualizer(VISUALIZATION_TYPE.MAP)
                }
              />
              <Badge badgeContent={filterCount} color="primary">
                <Button onClick={onClickFilter}>
                  <Box>+ Filter</Box>
                </Button>
              </Badge>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              padding={2}
              bgcolor="rgba(255,255,255,0.1)"
              borderRadius="12px"
            >
              <Button value=".CSV" onClick={onClickExportCsv} />
              <Button value=".XLSX" onClick={onClickExportXlsx} />
              <Button value=".GEOJSON" onClick={onClickExportGeoJson} />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              padding={2}
              bgcolor="rgba(255,255,255,0.1)"
              borderRadius="12px"
            >
              <Button
                onClick={() => onClickSaveDashboard && onClickSaveDashboard()}
              >
                <SaveIcon />
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Stack component="div" height="100%">
          <Grid container width="100%">
            <Grid item xs={4}>
              <Paper
                elevation={0}
                sx={{
                  margin: 1,
                  padding: 2,
                  backgroundColor: "rgba(0,0,0,0)",
                  outline: "1px solid white",
                  color: "white",
                }}
              >
                <Stack alignItems="center" justifyContent="center">
                  <Typography fontSize={60}>{nRespondents}</Typography>
                  <Typography fontSize={12}>SVAR</Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                elevation={0}
                sx={{
                  margin: 1,
                  padding: 2,
                  backgroundColor: "rgba(0,0,0,0)",
                  outline: "1px solid white",
                  color: "white",
                }}
              >
                <Stack alignItems="center" justifyContent="center">
                  <Typography fontSize={60}>{nAnswerPoints}</Typography>
                  <Typography fontSize={12}>DATAPUNKTER</Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                elevation={0}
                sx={{
                  margin: 1,
                  padding: 2,
                  backgroundColor: "rgba(0,0,0,0)",
                  outline: "1px solid white",
                  color: "white",
                }}
              >
                <Stack alignItems="center" justifyContent="center">
                  <Typography fontSize={60}>{nMapMarkers}</Typography>
                  <Typography fontSize={12}>KARTPUNKTER</Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={0}
                sx={{
                  margin: 1,
                  padding: 2,
                  backgroundColor: "rgba(0,0,0,0)",
                  outline: "1px solid white",
                  color: "white",
                  height: "400px",
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  height="100%"
                >
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={answerDateData}>
                      <XAxis
                        stroke="white"
                        dataKey="date"
                        tickFormatter={dateFormatter}
                      />
                      <Tooltip content={<CustomTooltip />} />
                      <YAxis stroke="white" />
                      <Line type="monotone" dataKey="value" stroke="#ea00ff" />
                      <CartesianGrid strokeDasharray="3 3" stroke="#cccccc18" />
                    </LineChart>
                  </ResponsiveContainer>
                  <Typography fontSize={18}>SVARSFREKVENS</Typography>
                </Stack>
              </Paper>
            </Grid>
            {visualizers?.map((dv, i) => (
              <Grid item xs={dv.size ? dv.size : 12}>
                {dv.type === VISUALIZATION_TYPE.GRAPH && (
                  <DataVisualizer
                    key={i}
                    id={dv.id}
                    type={dv.chartType}
                    globallyFilteredRespondents={globallyFilteredRespondents}
                    survey={survey}
                    graphQuestionId={dv.questionId}
                    filters={dv.filters}
                    onClickDelete={onClickDeleteVisualizer}
                    onClickResize={onClickResizeVisualizer}
                    onClickFilter={onClickEditVizualiserFilter}
                  />
                )}
                {dv.type === VISUALIZATION_TYPE.MAP && (
                  <MapVisualizer
                    key={i}
                    id={dv.id}
                    globallyFilteredRespondents={globallyFilteredRespondents}
                    survey={survey}
                    graphQuestionId={dv.questionId}
                    onClickDelete={onClickDeleteVisualizer}
                    onClickResize={onClickResizeVisualizer}
                    onClickFilter={onClickEditVizualiserFilter}
                    filters={dv.filters}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
    </FullScreenRow>
  );
};

export default AnalyzeViewPage;
