export enum QUESTION_TYPE {
  TEXT = "text",
  INPUT = "input",
  RADIO = "radio",
  RADIO_MAP = "radioMap",
  RADIO_CONDITION = "radioCondition",
  RADIO_MULTI = "radioMulti",
  CHECKBOX = "checkbox",
  DROPDOWN = "dropdown",
  DROPDOWN_CONDITION = "dropdownCondition",
  MAP_MARKER = "mapMarker",
  MAP_LINE = "mapLine"
}

export enum SURVEY_SETTING {
  ANONYMOUS = "anonymous",
  MULTIPLE_RESPONSES = "multipleResponses",
  ARCHIVED = "archived",
  PUBLISHED = "published"
}

export enum PAGE_SETTING {
  REQUIRED = "required",
  HIDDEN = "hidden"
}

export enum RADIO_QUESTION_SETTING {
  MULTISELECT = "multi"
}

export enum TOOLMODE {
  MAP_MARKER = "mapMarker"
}

export enum VISUALIZATION_TYPE {
  MAP = "map",
  GRAPH = "graph"
}
