import {
  Badge,
  ButtonBase,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import { MapVisualizerViewProps } from "./MapVisualizer.types";
import {
  // GeolocateControl,
  Layer,
  Map as MapMapbox,
  NavigationControl,
  Source
} from "react-map-gl";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import AspectRatioICon from "@mui/icons-material/AspectRatio";
import mapboxgl from "mapbox-gl";
import { Circle, FitScreen, HeatPump } from "@mui/icons-material";

const token =
  "pk.eyJ1IjoiaW5vZGlubyIsImEiOiJjbG5mNWxuN3UwajY4MmtvaHh0dmE1dnBzIn0.3rvwbu7fkYOM9jRMxl7kwA";

const MapVisualizerView = (props: MapVisualizerViewProps) => {
  const {
    data,
    dataSettings,
    mapRef,
    graphTitle,
    filterCount,
    showHeatmap,
    showData,
    heatmapSettings,
    onClickDelete,
    onClickResize,
    onClickFilter,
    onClickFitToScreenIcon,
    onClickDataIcon,
    onClickHeatmapIcon
  } = props;

  return (
    <Paper
      elevation={0}
      sx={{
        margin: 1,
        backgroundColor: "rgba(0,0,0,0)",
        outline: "1px solid white",
        color: "white",
        height: "600px",
        padding: 2
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          alignItems="flex-start"
          spacing={2}
        >
          <Typography fontSize={18}>{`${graphTitle}`}</Typography>
          <Stack
            bgcolor="rgba(255,255,255,0.1)"
            borderRadius="12px"
            direction="row"
            spacing={0}
          >
            <IconButton onClick={onClickResize}>
              <AspectRatioICon sx={{ color: "white" }} />
            </IconButton>
            <IconButton onClick={onClickDelete}>
              <DeleteIcon sx={{ color: "white" }} />
            </IconButton>
            <Badge badgeContent={filterCount} color="primary">
              <IconButton onClick={onClickFilter}>
                <FilterListIcon sx={{ color: "white" }} />
              </IconButton>
            </Badge>
          </Stack>
        </Stack>
        <Stack width="100%" height="100%" position="relative">
          <MapMapbox
            ref={mapRef}
            reuseMaps
            mapLib={mapboxgl}
            mapboxAccessToken={token}
            style={{ flex: 1 }}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            onRender={(event) => event.target.resize()}
          >
            {/* <GeolocateControl /> */}
            <NavigationControl />
            <Source id="data" type="geojson" data={data}>
              {showHeatmap && (
                <Layer
                  id="heatmap-layer"
                  type="heatmap"
                  source="data"
                  paint={{
                    "heatmap-intensity": heatmapSettings?.intensity ?? 2,
                    "heatmap-radius": heatmapSettings?.radius ?? 20,
                    "heatmap-opacity": heatmapSettings?.opacity ?? 0.2,
                    "heatmap-color": [
                      "interpolate",
                      ["linear"],
                      ["heatmap-density"],
                      0,
                      "rgba(0, 0, 255, 0)",
                      0.1,
                      "royalblue",
                      0.3,
                      "cyan",
                      0.5,
                      "lime",
                      0.7,
                      "yellow",
                      1,
                      "red"
                    ]
                  }}
                />
              )}
              {showData && (
                <Layer
                  id="data-layer"
                  type="circle"
                  source="data"
                  paint={{
                    "circle-radius": dataSettings.radius ?? 4,
                    "circle-color": dataSettings.color ?? "#bb00ff",
                    "circle-opacity": dataSettings.opacity ?? 0.4,
                    "circle-stroke-color":
                      dataSettings.strokeColor ?? "#bb00ff",
                    "circle-stroke-width": dataSettings.strokeWidth ?? 2
                  }}
                />
              )}
            </Source>
          </MapMapbox>
          <Stack position="absolute" left="12px" top="12px" spacing={1}>
            <Tooltip title="Anpassa till data">
              <ButtonBase onClick={onClickFitToScreenIcon}>
                <FitScreen
                  sx={{
                    bgcolor: "white",
                    padding: 0.4,
                    borderRadius: "4px",
                    color: "black",
                    outline: "2px solid rgba(213, 209, 206, 0.8)",
                    width: "23px",
                    height: "23px"
                  }}
                />
              </ButtonBase>
            </Tooltip>
            <Tooltip title={showData ? "Dölj Data" : "Visa Data"}>
              <ButtonBase onClick={onClickDataIcon}>
                <Circle
                  sx={{
                    bgcolor: "white",
                    padding: 0.4,
                    borderRadius: "4px",
                    color: showData ? "#bb00ff" : "black",
                    outline: "2px solid rgba(213, 209, 206, 0.8)",
                    width: "23px",
                    height: "23px"
                  }}
                />
              </ButtonBase>
            </Tooltip>
            <Tooltip title={showHeatmap ? "Dölj Heatmap" : "Visa Heatmap"}>
              <ButtonBase onClick={onClickHeatmapIcon}>
                <HeatPump
                  sx={{
                    bgcolor: "white",
                    padding: 0.4,
                    borderRadius: "4px",
                    color: showHeatmap ? "#bb00ff" : "black",
                    outline: "2px solid rgba(213, 209, 206, 0.8)",
                    width: "23px",
                    height: "23px"
                  }}
                />
              </ButtonBase>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default MapVisualizerView;
